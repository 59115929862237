import axios from 'axios';
import CryptoJS from 'crypto-js'; 

const baseURL = process.env.REACT_APP_API_URL;
//const baseURL = 'https://pallevar-backend-service-pr-3.onrender.com';

const csrfToken = process.env.REACT_APP_API_TOKEN;

export const obtieneOrdenes = async (restaurantId) => { 
    console.log("restaurantId:",restaurantId)
    try {
          
      const response = await axios.get(`${baseURL}/procesaOrden/${restaurantId}`, {
          headers: {
              'X-CSRFToken': csrfToken,
              'Content-Type': 'application/json',
              'Authorization': `Bearer X`,
              'Accept': 'application/json'
          }
      });  
      // console.log(response.data.orders)
      if (response) {
          return response.data.orders;
      }
      if (response.status === 404){
          console.log('El producto no fue encontrado para el usuario:', restaurantId);
          return null;
      }
    } catch (error) {
        console.error('Error al obtener la información del usuario:', error);
        throw new Error('Error al obtener la información del usuario:', error);
    }
}

export const actualizaEstadoOrden = async (orderId, nuevoEstado, time) => { 
    console.log(orderId, nuevoEstado, time)
    try {
        const userData = { orderId, nuevoEstado, time };
        const response = await axios.put(`${baseURL}/procesaOrden`, userData, {
            mode: "cors",
            headers: {
              'X-CSRFToken': csrfToken,
              'Content-Type': 'application/json',
              'Authorization': 'BeareraccessToken',
              'Accept': 'application/json'
            },
          });
    
        if (response) {
          return response;
        }
    
        if (response.status === 404) {
          console.log('El producto no se actualizo');
          return null;
        }
      } catch (error) {
        console.error('Error al actualizar el registro:', error);
        throw new Error('Error al actualizar el registro:', error);
      }
}



export const login = async (email, password) => {
 
  try{
    const hPassword = CryptoJS.SHA256(password).toString();
    const userData = { email, hPassword };
    const response = await axios.post(`${baseURL}/login-comanda/`, userData, {
      mode: "cors",
      headers: {
        'X-CSRFToken': csrfToken,
        'Content-Type': 'application/json',
        'Authorization': 'BeareraccessToken',
        'Accept': 'application/json'
      },
    });
    console.log(response.data)
    return response.data; 

    }catch(e){
        if (e.response) {
        throw new Error(e.response.data.message || 'Error al iniciar sesion de usuario');
      } else {
        throw new Error('Error de red o problema con la solicitud');
      }
    }
  };