import React, { useEffect, useState } from 'react';
import { obtieneOrdenes } from '../services/authService';
import OrderCard from '../components/OrderCard';
import Header from '../components/HeaderComponent';
import Loader from '../components/Loader';
import styles from '../css/orders.module.css';

const Comanda = () => {
    const restaurantNombre = localStorage.getItem('userid');
    console.log("restaurantid:",restaurantNombre)
    const [ordenes, setOrdenes] = useState([]);
    const [loading, setLoading] = useState(true);

    // Función para solicitar permiso de notificación
    // const requestNotificationPermission = () => {
    //     if (Notification.permission !== 'denied') {
    //         Notification.requestPermission().then(permission => {
    //             if (permission === 'granted') {
    //                 console.log('Permiso para notificaciones concedido');
    //             }
    //         });
    //     }
    // };

    useEffect(() => {
        // Llama a la función para solicitar permiso de notificaciones
        // requestNotificationPermission();

        // Función para obtener órdenes iniciales
        const fetchOrdenes = async () => {
            try {
                const response = await obtieneOrdenes(restaurantNombre);
                console.log(response)
                setOrdenes(response || []);
            } catch (error) {
                console.error('Error al obtener las órdenes:', error);
            } finally {
                setLoading(false);
            }
        };

        // Llama a la función para obtener las órdenes
        fetchOrdenes();

        // Configura WebSocket para recibir actualizaciones en tiempo real
        const socket = new WebSocket('wss://api.pallevar.me');

        socket.onopen = () => {
            console.log('Conectado al servidor WebSocket');
        };

        socket.onmessage = (event) => {
            const nuevaOrden = JSON.parse(event.data);

            // Muestra la notificación para la nueva orden
            // showNotification(nuevaOrden);

            // Actualiza el estado de las órdenes con la nueva orden
            setOrdenes((prevOrdenes) => {
                const existe = prevOrdenes.some(order => order.orderId === nuevaOrden.orderId);
                if (nuevaOrden.orderStatus === "Entregado") {
                    // Si el estado es "Entregado", eliminamos la orden del estado
                    return prevOrdenes.filter(order => order.orderId !== nuevaOrden.orderId);
                }
                if (existe) {
                    return prevOrdenes.map(order => order.orderId === nuevaOrden.orderId ? nuevaOrden : order);
                } else {
                    return [...prevOrdenes, nuevaOrden];
                }
            });
        };

        socket.onclose = () => {
            console.log('Conexión WebSocket cerrada');
        };

        return () => {
            socket.close();
        };
    }, [restaurantNombre]);

    // Función para mostrar notificaciones
    // const showNotification = (order) => {
    //     if (Notification.permission === 'granted') {
    //         const notification = new Notification('Nueva Orden', {
    //             body: `Pedido #${order.orderId} ha llegado.`,
    //             icon: 'url_del_icono', // Opcional: agrega la URL de un icono si lo deseas
    //         });

    //         notification.onclick = () => {
    //             console.log('Notificación clicada');
    //         };
    //     }
    // };

    // Ordenar las órdenes por orderId
    const ordenesOrdenadas = ordenes.sort((a, b) => a.orderId - b.orderId);


    return (
        <div className={styles.divBody}>
            <Header />
            <div className={styles.ordersContainer}>
                {loading ? (
                    <div className={styles.loadingContainer}>
                        <Loader />
                    </div>
                ) : (
                    <div className={styles.comandasContainer}>
                        {ordenesOrdenadas.filter(order => order.orderStatus !== "Entregado").length === 0 ? (
                            <p>No hay órdenes disponibles.</p>
                        ) : (
                            ordenesOrdenadas
                                .filter(order => order.orderStatus !== "Entregado")
                                .map(order => (
                                    <OrderCard key={order.orderId} order={order} />
                                ))
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Comanda;
